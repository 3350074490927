.header {
    background-color: white;
    text-align: center;
    height: 52px;
    border-bottom: 1px solid #EBEBEB;
}

.menu-btn {
    position: absolute;
    left: 10px;
    top: 10px;

    &:active,
    &:hover {
        color: #683FD2;
        border-color: #683FD2;
    }
}

@media (min-width: 992px) {
    .menu-btn {
        display: none;
    }
}

.logo {
    display: inline-block;
    text-transform: uppercase;
    margin-top: 15px;
}


.menu-drawer {
    .ant-drawer-header {
        padding-left: 15px;
    }

    .ant-drawer-body {
        padding: 0;
        margin-top: 10px;
    }
}