@import "../../global.scss";
@import "../../colors.scss";

$success: #683FD2;
$primary-purple: #722ed1;
$dark-purple: darken($primary-purple, 10%);
$text-dark: #262626;
$text-secondary: #666666;
$border-light: #d9d9d9;
$spacing-base: 8px;

.appointments-component {
    width: 100%;

    .date-0 .page-header {
        border-top: 0;
    }

    .page-header {
        padding-top: 15px;
        padding-bottom: 15px;
        border-top: 0;
    }

    .today-tag {
        float: right;
        background: $success;
        border-color: $success;
        font-size: 14px;
    }

    .adm-swipe-action-actions {
        background: #7856D8;

        button {
            background: $success;
            font-size: 13px;
        }

        button:nth-child(2n+2) {
            background: #7856D8;
        }
    }

    .swipe-action-content {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgb(235, 235, 235);
    }

    .appointment-timing {
        padding-left: 10px;
        width: 90px;
        font-size: 14px;
    }

    .appointment-patient {
        font-weight: 500;
        padding-bottom: 10px;
        display: block;
    }



    .appointment-start,
    .appointment-patient {
        margin-bottom: 2px;
        display: block;
        font-weight: 500;
    }

    .appointment-patient {
        color: $success;
        text-transform: uppercase;
    }


    .appointment-end {
        color: #969697;
    }

    .edit-btn {
        font-size: 14px;
    }


    .tubes {
        color: gray;
        margin-left: -1px;
    }

    .tube-item {
        margin-left: 3px;
        color: gray;
        border-color: lightgray;

        // display: inline-flex;
    }



    .tube-container {
        display: flex;
        flex-wrap: wrap;
    }

    .panel-item .tube-item:last-child {
        margin-right: 0px;

    }

    .panel-item {
        color: gray;
        text-transform: none;
        display: block;
    }

    .appointment-card {
        background: #fff;
        margin: 12px 0;
        overflow: hidden;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

        .swipeable-content {
            padding: 16px;
        }

        .appointment-start {
            font-size: 16px;
            color: $primary_text;
        }
    }

    .appointment-notes {
        text-transform: none;
    }

    .appointment-patient {
        padding: 12px 0;
        border-bottom: 1px solid #f0f0f0;

        &:last-child {
            border-bottom: none;
        }
    }

    .tube-item {
        margin: 4px;
        padding: 4px 8px;
        border-radius: 12px;
    }

    .adm-swipe-action {
        &-action {
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 24px;
            font-size: 16px;
            white-space: nowrap;

            &:active {
                opacity: 0.8;
            }
        }
    }

}

.adm-modal-body .adm-modal-content {
    padding: 0px;
}

.adm-modal-content {
    border-radius: 0px;
}

.swipeable-content {
    padding: $spacing-base * 2;

    .time {
        font-size: 1.5rem;
        font-weight: 600;
        color: $text-dark;
        margin-bottom: $spacing-base;
        display: block;
    }

    .appointment-patient {
        background: #fff;
        border-radius: $spacing-base;
        padding: $spacing-base * 2;
        margin-bottom: $spacing-base;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
    }
}

.appointment-address {
    display: flex;
    align-items: center;
    color: $text-secondary;
    font-size: 1rem;
    margin-bottom: $spacing-base * 3;
    cursor: pointer;
    transition: color 0.2s ease;

    .anticon {
        margin-right: $spacing-base/2;
        font-size: 1.1rem;
        // color: $primary-purple;
    }

    &:hover {
        color: $primary-purple;
    }
}

.patient {
    &-name {
        font-size: 1.2em;
        color: $primary-purple;

        &-container {
            display: flex;
            align-items: center;
            gap: $spacing-base;
            margin-bottom: $spacing-base;
        }
    }

    &-address {
        display: flex;
        align-items: center;
        margin-bottom: $spacing-base * 1.5;
        cursor: pointer;
        color: $text-dark;
        font-size: 0.95em;

        .anticon {
            margin-right: $spacing-base;
            color: $primary-purple;
        }

        &:hover {
            color: $primary-purple;
        }
    }
}

.panel-item {
    margin-bottom: $spacing-base * 1.5;
    padding: $spacing-base*1.5;
    background: rgba(0, 0, 0, 0.02);
    border-radius: $spacing-base/2;


    &-lab {
        font-weight: 500;
        color: $text-dark;
        margin-bottom: $spacing-base/2;

        .anticon {
            margin-right: $spacing-base/2;
            color: $primary-purple;
        }
    }
}

.tube {
    &-container {
        display: flex;
        flex-wrap: wrap;
        gap: $spacing-base/2;
        margin-top: $spacing-base;
    }

    &-item {
        border: 1px solid $border-light;
        background: #f5f5f5;
        padding: $spacing-base/2 $spacing-base;
        border-radius: 4px;

        b {
            margin-right: 4px;
        }
    }
}

.appointment-notes {
    margin-top: $spacing-base * 2;
    border-radius: $spacing-base/2;
}

.biometric {


    &-form {
        &-header {
            margin-bottom: $spacing-base * 2;
            text-align: center;
        }

        &-scroll-container {
            max-height: 80vh;
            overflow-y: auto;
        }
    }
}