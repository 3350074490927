$primary: #683FD2;




.biometric-form-header {
    text-align: center;
    padding: 0px 0 10px;
    font-weight: 500;
    color: rgb(51, 51, 51);
}

.header-label {
    color: #969697;
}

.patient-name {
    color: $primary;
    text-transform: uppercase;
}

.adm-form-item-label,
.adm-input input,
.adm-button,
.adm-selector-item,
.height-btn {
    font-size: 14px;
}

.adm-list-item-content-prefix,
.adm-list-item-content-main {
    border: 0px;
    vertical-align: middle;
}

.adm-list-item-content {
    padding: 0;
    border-top: none;
}

.adm-modal-wrap {
    max-width: 800px;
    width: 95%;
    margin: 16px auto;
}

.adm-modal-wrap>div {
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
}

.adm-modal-body {
    padding: 0px;
    height: 100%;
    overflow-y: auto;
}

.adm-list-body {
    border-bottom: 0;
}

.adm-modal-content {
    padding: 0;
    height: 100%;
    max-height: 100%;
}

.adm-form-item {
    padding-left: 0;
}

.adm-form-item-label {
    padding-left: 10px;
}

.adm-input-disabled {
    opacity: 1 !important;
}

input:disabled {
    opacity: 1 !important;
}

.index-value {
    float: right;
    margin-top: 2px;
    color: #969697;
}

.modal-footer {
    padding: 16px;

    .button-stack {
        display: flex;
        flex-direction: column;
        gap: 12px;
        max-width: 400px;
        margin: 0 auto;

        .confirm-btn {
            background: $primary;
            color: white;


        }

        .cancel-btn {
            border: 1px solid #d9d9d9;

            &:hover {
                border-color: #4096ff;
                color: #4096ff;
            }
        }
    }
}

.bp-input {
    width: 25%;
    min-width: 45px;
    float: left;
}

.bp-slash {
    font-size: 20px;
    text-align: center;
    color: #666;
    margin-top: -10px;

}

.adm-modal-header {
    text-align: center;
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;

    .adm-modal-title {
        font-weight: 600;
        font-size: 18px;
    }
}


// Modern form styling
.adm-form {
    .adm-input {
        border: 1px solid #e8e8e8;
        border-radius: 8px;
        padding: 12px;
        transition: all 0.3s ease;
        background: #f8f9fa;

        &:focus {
            border-color: #1890ff;
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
            background: white;
        }

        &::placeholder {
            color: #bbb;
        }
    }

    .adm-text-area {
        border-radius: 8px;
        border: 1px solid #e8e8e8;
        padding: 12px;
        min-height: 120px;
        background: #f8f9fa;

        &:focus {
            border-color: #1890ff;
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
            background: white;
        }
    }

    // Card styling
    .adm-card {
        border-radius: 0px;
        margin-bottom: 16px;
        padding: 0;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

        &-header {
            padding: 16px 10px;
            border-bottom: 1px solid #f0f0f0;
            font-weight: 600;
        }

        &-body {
            padding: 0;
        }
    }

    // Button styling
    .height-btn {
        width: 100%;
        border-radius: 8px;
        padding: 12px;
        background: #f8f9fa;
        border: 1px solid #e8e8e8;
    }

    .modal-footer {
        display: flex;
        gap: 12px;
        justify-content: flex-end;

        .confirm-btn {
            background: #1890ff;
            color: white;
            border-radius: 8px;
            padding: 12px 24px;

            &:hover {
                background: darken(#1890ff, 5%);
            }
        }

        .cancel-btn {
            border-radius: 8px;
            padding: 12px 24px;
            border: 1px solid #e8e8e8;

            &:hover {
                background: #f8f9fa;
            }
        }
    }

    // BP slash styling
    .bp-slash {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 20px;
        color: #666;
    }
}



.modal-footer {
    .button-stack {
        display: flex;
        flex-direction: row;
        gap: 8px;

        .confirm-btn,
        .cancel-btn {
            flex: 1;
            height: 44px;
            line-height: 44px;
            padding: 0 25px;
            margin: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;

        }
    }
}

.biometric-form {
    .adm-card {
        margin-bottom: 16px;
        // border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

        .adm-card-header {
            font-size: 16px;
            font-weight: 600;
            border-bottom: 1px solid #f0f0f0;
            padding: 16px;
        }

        .adm-card-body {
            padding: 16px;
        }
    }

    .adm-form-item {
        margin-bottom: 16px;
        border-top: none;

        &::before,
        &::after {
            display: none;
        }

        .adm-input {
            font-size: 14px;
            padding: 8px 12px;
            border-radius: 6px;
            border: 1px solid #d9d9d9;

            &:focus {
                border-color: #1890ff;
                box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            }
        }
    }

    .bp-slash {
        font-size: 20px;
        text-align: center;
        color: #666;
    }

    .button-stack {
        .confirm-btn {
            margin-bottom: 12px;
            border-radius: 6px;
        }

        .cancel-btn {
            border-radius: 6px;
        }
    }
}

.biometric-form {
    // ... previous styles ...

    .adm-card {
        // Update card styling to match screenshot
        background: #ffffff;
        margin: 16px 0;
        border: none;

        .adm-card-header {
            padding: 20px 16px 12px;

            // Match the screenshot header style
            .anticon {
                color: #666;
                margin-right: 8px;
            }
        }
    }

    // Match the screenshot input styling
    .adm-input {
        background: #f8f9fa;
        border-radius: 8px;
        height: 44px;
        font-size: 16px;

        &::placeholder {
            color: #a0a0a0;
        }
    }

    // Blood pressure layout improvements
    .bp-container {
        display: flex;
        align-items: center;
        gap: 8px;

        .bp-input {
            flex: 1;
            max-width: 120px;
        }

        .bp-slash {
            font-size: 24px;
            color: #666;
            margin: 0 4px;
        }
    }

    // Match screenshot text styles
    .adm-form-item-label {
        color: #666;
        font-size: 15px;
    }

    // Match screenshot button styles
    .button-stack {
        padding: 16px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.05);

        .confirm-btn {
            background: #1677ff;
            border-radius: 8px;
            height: 44px;
            font-size: 16px;
        }

        .cancel-btn {
            border: none;
            color: #666;
            height: 44px;
            font-size: 16px;
        }
    }

    // Notes textarea styling
    .adm-text-area {
        background: #f8f9fa;
        border-radius: 8px;
        padding: 12px;
        min-height: 120px;

        &::placeholder {
            color: #a0a0a0;
        }
    }

    .adm-form-item {
        margin-bottom: 16px;
        border-top: none;

        // Align labels vertically with inputs
        .adm-form-item-label {
            display: flex;
            align-items: center;
            min-height: 44px; // Match input height
            color: #666;
            font-size: 15px;
        }

        // Ensure input container is properly aligned
        .adm-form-item-content {
            display: flex;
            align-items: center;
        }
    }

    // Ensure list items maintain vertical alignment
    .adm-list-item {
        align-items: center;
        min-height: 44px;
    }

    // Adjust height button to match other inputs
    .height-btn {
        height: 44px;
        line-height: 44px;
        text-align: left;
        padding: 0 12px;
    }
}