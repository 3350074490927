$success: #683FD2;

.patient-profile-component {
    .patient-profile-header {
        text-align: center;
        padding: 20px 0;
        font-weight: 500;
        color: rgb(51, 51, 51);
        border-bottom: 1px solid rgb(235, 235, 235);
    }

    .header-label {
        color: #969697;
    }

    .adm-form-item-label,
    .adm-input input,
    .adm-button,
    .adm-selector-item {
        font-size: 14px;
    }

    .adm-modal-wrap {
        max-width: 95%;
        width: 95%;
        height: 98%;
    }

    .adm-modal-wrap>div {
        height: 100%;
    }

    .adm-modal-body {
        padding-top: 0;
        height: 100%;
        position: relative;
        max-height: 100%;
    }

    .adm-list-body {
        border-bottom: 0;
    }

    .adm-modal-content {
        padding: 0;
        max-height: 100%;
    }

    .patient-profile-content {
        padding: 0 10px;
    }

    .ant-descriptions-header {
        margin-top: 20px;
    }

    .adm-form-item {
        padding-left: 0;
    }

    .patient-appointment-status {
        text-transform: capitalize;
    }

    .ant-descriptions-item-label {
        width: 110px;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
        padding: 15px;
    }

    .in-progress-icon {
        background: $success;
        width: 8px;
        height: 8px;
        float: left;
        margin-top: 7px;
        margin-right: 7px;
        display: block;
        border-radius: 50%;
    }

    .modal-footer {
        margin-top: 20px;
        padding: 10px 10px 0 10px;
        border-top: 1px solid rgb(235, 235, 235);
    }

    .cancel-btn {
        font-size: 16px;
        display: block;
        width: 100%;
        height: 47px;
    }

    .order-info-item {
        margin-bottom: 4px;

        &:last-child {
            margin-bottom: 0;
        }

        .ant-tag {
            margin: 4px;
            padding: 4px 8px;
            font-size: 13px;

            &:first-child {
                margin-left: 8px;
            }
        }
    }
}