.login-component {
    width: 100%;

    .login-form {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        border: 0;
        margin-top: 20px;
    }

    .adm-list-body {
        border: 0;
    }

    .login-title {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        margin-top: 20px;
    }

    button {
        width: calc(100%);
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        height: 40px;
    }
}