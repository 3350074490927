@page {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    size: landscape;
}

.print-button {
    width: 204px;
    text-align: center;
    padding: 10px;
}

.print-labels-component {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .label {
        width: 206px;
        height: 96px;
        page-break-after: always;
        border: 1px dashed lightgray;
    }

    .barcode-container {
        margin: 1px auto;
        width: 100%;

        svg {
            width: 100%; //inherit;
            height: 55%;
        }

    }

    .label-cell {
        margin: 0;
        width:100%;
    }

    .instalab-cell-content,
    .undefined-cell-content,
    .lab-cell-content {
        overflow-x: hidden;
        padding: 3px 3px 0;
    }

    .instalab-data {
        overflow-x: hidden;

        td {
            padding: 2px;
        }

        tr td:last-child {
            max-width: 190px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .lab-table {

        font-size: 10px;


        tr td:first-child {
            font-weight: bold;
        }

        tr td:last-child {
            max-width: 175px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    table {
        width: 100%;
    }

    td {
        padding-right: 2px;
        line-height: 1;
    }
}

@media print {
    .print-button {
        display: none;
    }

    .print-labels-component .label {
        border: 0px;
    }
}