$success: #683FD2;

.appointment-status-tag-component {
    font-weight: 500;
    text-transform: capitalize;
    font-weight: normal;

    .in-progress-dot {
        background: $success;
        width: 8px;
        height: 8px;
        float: left;
        margin-top: 7px;
        margin-right: 7px;
        border-radius: 50%;
    }

}