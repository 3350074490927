html,
body {
    width: 100%;
}

.layout {
    background: white;
    width: 100%;
    max-width: 100%;
}

.page-header {
    color: #969697;
    background: rgb(245, 247, 250);
    margin: 0;
    font-size: 13px;
    padding: 10px;
    border-top: 1px solid rgb(235, 235, 235);
    border-bottom: 1px solid rgb(235, 235, 235);
}

.ant-menu-item-selected {
    color: #683FD2;
    background: rgba(104, 63, 210, 0.1) !important;
}

.ant-menu-item-selected:after {
    border-color: #683FD2 !important;
}

/* Menu item */

.ant-menu-item:active {
    background: rgba(104, 63, 210, 0.1);
    color: #683FD2 !important;
}

.ant-menu-item:hover {
    color: #683FD2 !important;
}

.ant-menu-item-active {
    color: #683FD2 !important;
}

/* Alerts */

.ant-message-notice svg {
    color: #683FD2;
}

.ant-message-error svg {
    color: #E65254;
}

/* Buttons */

.adm-button-primary {
    background: #683FD2;
    border-color: #683FD2;
}

/* Selector */

.adm-selector-item-multiple-active,
.adm-selector-item-active {
    background: rgba(104, 63, 210, 0.1);
    color: #683FD2;

    .adm-selector-check-mark-wrapper {
        border-top: solid 10px transparent;
        border-bottom: solid 10px #683FD2;
        border-left: solid 10px transparent;
        border-right: solid 10px #683FD2;
    }
}

// List

a.adm-list-item:active:not(.adm-list-item-disabled) {
    background: rgb(245, 247, 250) !important;
}

a.adm-list-item:hover:not(.adm-list-item-disabled) {
    .adm-list-item-content-main {
        color: rgb(51, 51, 51);
    }
}

// Dialog

.adm-dialog {
    button {
        background: white;
        color: #683FD2;
    }
}

// Select

.ant-select-item-option-selected {
    background: rgba(104, 63, 210, 0.1) !important;
    font-weight: 400 !important;
}

.ant-select {
    &:hover .ant-select-selector {
        border-color: #683FD2 !important;
    }

    .ant-select-selector {
        box-shadow: none !important;
    }
}

.ant-select-item-option-state {
    .anticon-check {
        color: #683FD2;
    }
}

.ant-select-item-option-content {
    font-size: 13px;
} 

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #683FD2 !important;
}


// Swiper

.adm-page-indicator-dot-active {
    background: #683FD2;
}

// Spin

.ant-spin {
    color: #683FD2;
    
    .ant-spin-dot-item {
        background: #683FD2;
    }
}

// DatePicker

.ant-picker-cell-selected {
    .ant-picker-cell-inner {
        background: #683FD2 !important;
        border-color: #683FD2;
    }
}

.ant-picker-cell-today {
    .ant-picker-cell-inner:before {
        border-color: #683FD2 !important;
    }
}

.ant-picker-time-panel-cell-selected {
    .ant-picker-time-panel-cell-inner {
        background: rgba(104, 63, 210, 0.1) !important;
    }
}

.ant-picker-footer {
    .ant-picker-now-btn {
        color: #683FD2;
    }

    .ant-btn-primary:not([disabled]) {
        background: #683FD2;
        border-color: #683FD2;
    }
}

.ant-picker-focused,
.ant-picker:hover {
    box-shadow: none;
    border-color: #683FD2;
}

// Tables

.ant-table-row-selected .ant-table-cell {
    background: rgba(104, 63, 210, 0.1) !important; 
}

.ant-checkbox-checked .ant-checkbox-inner {
    background: #683FD2;
    border-color: #683FD2;
}

.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: #683FD2;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background: #683FD2;
}

.ant-checkbox-checked:after {
    border: 1px solid #683FD2;
}

// Floating bubble

.adm-floating-bubble-button {
    width: auto;
    padding: 10px 25px;
    border-radius: 25px;
    font-size: 14px;
    background: #683FD2;

    svg {
        font-size: 20px;
        margin-right: 10px;
    }

    &:active:before {
        opacity: 0.08;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(calc(var(--border-width) * -1), calc(var(--border-width) * -1));
        width: 100%;
        height: 100%;
        background-color: #000;
        border: var(--border-width) var(--border-style) #000;
        border-radius: var(--border-radius);
        content: ' ';
        box-sizing: content-box;
    }
}

// Picker

.adm-picker-header-button,
.adm-picker-header-button:hover {
    color: #683FD2;
}