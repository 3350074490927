.badge-component {
    width: 100%;
    border: 5px solid black;
    font-family: "Segoe UI", Arial, sans-serif;

    .badge-photo-container {
        padding: 1rem 0 0;
        background-color: black;
    }

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 75%;
        background-color: black;
    }

    .badge-name-container {
        padding: 1rem 0 0;
    }

    .badge-name {
        font-size: 2.5rem;
        font-weight: bold;
        text-align: center;
        text-transform: capitalize;
    }

    .badge-title-container {
        padding: 0.5rem 0;
    }

    .badge-title {
        font-size: 1.75rem;
        color: grey;
        text-align: center;
    }

    .badge-qr-code-container {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        padding: 2.5rem 0 0;
    }
}